import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../components/Hero';
import ServiceList from '../components/ServiceList';

interface IncidentType {
  status: number;
  incidentAt: string;
}

export interface ServiceType {
  name: string;
  url: string;
  status: number;
  incidents: IncidentType[];
  visibility?: 'public' | 'private';
}

const StatusPage = () => {
  const [services, setServices] = useState<ServiceType[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [lastUpdatedAt, setLastUpdatedAt] = useState('');
  const [nextUpdateAt, setNextUpdateAt] = useState('');
  const { query } = useParams();

  const fetchServicesStatus = async () => {
    setIsFetching(true);

    return axios.get(`${process.env.REACT_APP_BASE_URL}`).then((res) => {
      const visibleServices = res.data.filter((service: ServiceType) => {
        if (query === 'all') return true;

        return service?.visibility !== 'private';
      });

      setServices(visibleServices);
      setLastUpdatedAt(moment().format('LTS'));
      setNextUpdateAt(moment().add(1, 'minutes').format('LTS'));
      setIsFetching(false);
    });
  };

  useEffect(() => {
    fetchServicesStatus();

    const timer = setInterval(() => {
      fetchServicesStatus();
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const allStatusOk = useMemo(() => {
    return services?.every((service) => {
      return service.status === 200;
    });
  }, [services]);

  const sortedServices = useMemo(() => {
    if (allStatusOk) {
      return services;
    }
    return services?.sort((a, b) => {
      return b.status - a.status;
    });
  }, [services, allStatusOk]);

  return (
    <>
      <div className='flex items-center justify-between'>
        <img className='h-10 w-10 rounded-md' src={require('../assets/img/profile.png')} alt='KevinYobeth logo' />
        <div className='text-right'>
          <p className='font-raleway text-lg font-bold text-white'>Service Status</p>
          {!isFetching ? (
            <>
              <p className='font-raleway text-sm font-bold text-gray-400'>Last updated at: {lastUpdatedAt}</p>
              <p className='font-raleway text-sm font-bold text-gray-400'>Next Update At: {nextUpdateAt}</p>
            </>
          ) : (
            <p className='font-raleway text-sm font-bold text-gray-400'>Fetching...</p>
          )}
        </div>
      </div>
      <Hero allIsOperational={allStatusOk} services={services} />
      <ServiceList services={sortedServices} />
    </>
  );
};

export default StatusPage;
