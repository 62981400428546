import { ServiceType } from '../pages/StatusPage';
import { isEmpty } from 'lodash';

interface Props {
  allIsOperational: boolean | undefined;
  services: ServiceType[];
}

const Hero = ({ allIsOperational, services }: Props) => {
  if (isEmpty(services)) {
    return (
      <div className='mx-auto mt-8 flex max-w-3xl items-center justify-center gap-x-5 rounded-md bg-white p-10 text-center drop-shadow-lg'>
        <div className='h-10 w-full animate-pulse rounded-md bg-gray-400'></div>
      </div>
    );
  }

  return (
    <div className='mx-auto mt-8 flex max-w-3xl flex-col items-center justify-center gap-5 rounded-md bg-white p-10 text-center drop-shadow-lg md:flex-row'>
      <span className='flex h-10 w-10 items-center justify-center'>
        <span
          className={`absolute inline-flex h-10 w-10 animate-ping rounded-full opacity-75 ${
            allIsOperational ? 'bg-green-400' : 'bg-red-400'
          } `}
        ></span>
        <span
          className={`relative inline-flex h-10 w-10 rounded-full ${allIsOperational ? 'bg-green-400' : 'bg-red-400'}`}
        ></span>
      </span>
      <h1 className='font-raleway text-3xl font-bold text-gray-900'>
        {allIsOperational ? 'All services operational' : 'Some services are down'}
      </h1>
    </div>
  );
};

export default Hero;
