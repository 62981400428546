import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import StatusPage from './pages/StatusPage';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <StatusPage />,
    },
    {
      path: '/:query',
      element: <StatusPage />,
    },
  ]);

  return (
    <div className='h-48 w-full bg-gray-900 py-12 px-8'>
      <div className='mx-auto max-w-3xl pb-8'>
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
