import moment from 'moment';
import { useMemo } from 'react';
import { ServiceType } from '../pages/StatusPage';

interface Props {
  service: ServiceType;
}

const LAST_N_DAYS = 30;

const Service = ({ service }: Props) => {
  const serviceIsUp = useMemo(() => {
    return service.status === 200;
  }, [service]);

  const transformedIncidents = useMemo(
    () =>
      service.incidents.reduce((acc, { incidentAt, status }) => {
        return { ...acc, [moment(incidentAt).format('YYYY-MM-DD')]: { status, incidentAt } };
      }, {}),
    [service.incidents]
  );

  const statusBar = useMemo(() => {
    const nDaysAgo = moment().subtract(LAST_N_DAYS, 'days');
    return Array.from({ length: LAST_N_DAYS }, (_, i) => {
      const day = nDaysAgo.clone().add(i + 1, 'days');
      const dayKey = day.format('YYYY-MM-DD');
      // @ts-ignore
      const dayIncident = transformedIncidents[dayKey];

      const dayStatus = dayIncident || service.status;
      const dayStatusClass = dayStatus === 200 ? 'bg-green-500 h-8' : 'bg-red-500 h-6';
      return (
        <div
          title={
            dayIncident
              ? `Status ${dayIncident.status} at ${moment(dayIncident.incidentAt).format('DD-MM-YYYY hh:mm a')}`
              : '100% Operational'
          }
          key={dayKey}
          className={`w-full ${dayStatusClass} rounded-sm`}
        />
      );
    });
  }, [service]);

  return (
    <a
      className='mx-auto mt-8 flex  max-w-3xl flex-col gap-x-5 rounded-md bg-white p-5 drop-shadow-lg transition-transform hover:scale-105'
      href={service.url}
      target='_blank'
      rel='noreferrer'
    >
      <div className='flex justify-between'>
        <p className='font-medium'>{service.name}</p>
        <p className={`font-bold ${serviceIsUp ? 'text-green-500' : 'text-red-500'}`}>
          {serviceIsUp ? 'Operational' : `Down - ${service.status}`}
        </p>
      </div>

      <div className='mt-3 flex items-end gap-x-1'>{statusBar}</div>
    </a>
  );
};

export default Service;
