import { isEmpty } from 'lodash';
import { ServiceType } from '../pages/StatusPage';
import Service from './Service';

interface Props {
  services: ServiceType[];
}

const ServiceList = ({ services }: Props) => {
  if (isEmpty(services)) {
    return (
      <>
        <div className='mx-auto mt-8 flex max-w-3xl justify-between gap-x-5 rounded-md bg-white p-5 drop-shadow-lg'>
          <p className='h-5 w-11/12 animate-pulse bg-gray-400'></p>
          <p className='h-5 w-1/6 animate-pulse bg-gray-400'></p>
        </div>
        <div className='mx-auto mt-8 flex max-w-3xl justify-between gap-x-5 rounded-md bg-white p-5 drop-shadow-lg'>
          <p className='h-5 w-11/12 animate-pulse bg-gray-400'></p>
          <p className='h-5 w-1/6 animate-pulse bg-gray-400'></p>
        </div>
        <div className='mx-auto mt-8 flex max-w-3xl justify-between gap-x-5 rounded-md bg-white p-5 drop-shadow-lg'>
          <p className='h-5 w-11/12 animate-pulse bg-gray-400'></p>
          <p className='h-5 w-1/6 animate-pulse bg-gray-400'></p>
        </div>
        <div className='mx-auto mt-8 flex max-w-3xl justify-between gap-x-5 rounded-md bg-white p-5 drop-shadow-lg'>
          <p className='h-5 w-11/12 animate-pulse bg-gray-400'></p>
          <p className='h-5 w-1/6 animate-pulse bg-gray-400'></p>
        </div>
      </>
    );
  }

  return (
    <>
      {services.map((service) => (
        <Service key={service.name} service={service} />
      ))}
    </>
  );
};

export default ServiceList;
